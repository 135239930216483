html {
	font-size: 16px;
	font-family: "Montserrat", serif;
	color: #424242;
}

html,
body {
	width: 100%;
	position: relative;
	margin: 0;
	padding: 0;
}

body {
	min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
	font-family: "Montserrat", sans-serif;

	a {
		color: inherit;
	}
}

ul,
p {
	margin: 0;
	padding: 0;
}

.wow {
	visibility: hidden;
}

a {
	color: $primary;
	text-decoration: none;
}

header {
	a:hover {
		text-decoration: none;
	}
}

main {
	overflow: hidden;
	clear: both;
	position: relative;
	padding-bottom: 2.5rem;
	min-height: 80vh;

	ul {
		margin: 0 0 0 2.5rem;
		padding: 0;
	}
}

.jumbotron {
	padding: 30px 0;
	border-radius: 0;
	border: none;
	margin: 0;
	color: $primary;

	h1 {
		color: currentColor;
		text-align: center;
		font-size: 2.5rem;
		margin: 0;
		font-weight: 400;
	}
}

footer {
	background: $primary;
	color: white;
	padding: 3rem 0;

	p {
		margin: 0;
		padding: 0;
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	a:hover {
		text-decoration: underline;
	}

	a i {
		color: inherit;
	}
}

.idiomes {
	list-style: none;

	li {
		float: left;
		display: inline;
		margin: 0 3px;
	}

	li a {
		color: #333;
	}

	li a.selected {
		color: $primary;
		font-weight: bold;
	}
}
