// Colors
$primary: #006400;
$secondary: #FFA500;
$greylight: #dcdcdc;
$black: #000000;
$login: $primary;
$yellow: #FEE50F;
$pistacho: #C9D58B;

// Menú
$menuBg: #fff;
$menuColor: #202124;
$menuColorHover: $primary;
$menuColorSelected: $primary;
//$menuColorSeparator: darken($menuBg, 20%);
$menuColorSeparator:#fff;
$menuBgSubmenu: #fff;

// Components
$boxBg: #f8f9fa;
$boxColorSeparator: #dadce0;
$boxColor: #202124;

// Components
$border-radius-sm: 0.6rem;
$border-radius-md: 1.5rem;
