/*==========  Mobile First Method  ==========*/
html {
	font-size: 14px;
}

#slide {
	color: #fff;

	h2 {
		font-size: 1rem;
		text-shadow: 2px 0 10px $primary;
	}

	p {
		font-size: 0.8rem;
		text-shadow: 2px 0 10px $primary;
	}
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
}

/* Small Devices, Tablets */
@media only screen and (min-width: 769px) {
	header {
		position: sticky;
		top: 0;
		z-index: 100;
		background: #fff;
		transition: 0.3s min-height;
		box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 5px 0 rgb(0 0 0 / 23%);

		&.compressed {
			/*	  #logo h1 {
			  font-size: 2rem;
			}
	  */
		}

		> .container:first-of-type {
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
		}

		.bg-menu {
			background: $menuBg;
			/*border-bottom: 1px solid $menuColorSeparator;*/
		}

		#logo {
			img {
				width: 250px;
				height: auto;
			}

			h1 {
				color: $primary;
				font-size: 2.5rem;
				transition: 0.3s all;
			}
		}
	}

	#slide {
		h2 {
			font-size: 3.5rem;
		}

		p {
			font-size: 2.5rem;
		}
	}

	.icons {
		display: flex;

		a {
			background: $boxBg;
			width: 2.4rem;
			height: 2.4rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100%;
			margin-left: 0.5rem;
			color: $boxColor;

			&:hover {
				color: #000;
			}
		}

		i {
			font-size: 1.2rem;
		}
	}
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
	html {
		font-size: 16px;
	}
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1400px) {
	html {
		font-size: 16px;
	}
}

/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
	.nivo-caption .tbl .tbl-cell {
		vertical-align: middle;
	}
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
	body {
		padding-top: 4rem;
	}

	.container {
		min-width: 100%;
	}

	.jumbotron {
		padding: 0;
		margin: 0;
	}
	.jumbotron h1 {
		font-size: 2rem;
		padding: 1rem;
	}

	footer .column {
		width: 100% !important;
	}

	.idiomes li a {
		padding: 0.4rem;
	}
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
}

@media (min-width: 1200px) {
	.container {
		max-width: 1200px;
	}
}
