// Fonts
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,500;0,700;1,100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

// Font Awesome (si ho carreguem així va més lent)
/*$fa-font-path: "/fonts/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";*/
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css");

// Dependences
@import "~animate.css";
@import "~swiper/css/bundle";
@import "~@fancyapps/fancybox/dist/jquery.fancybox.min.css";
@import "~superfish/dist/css/superfish.css";
@import "~owl.carousel";
@import "~datatables/media/css/jquery.dataTables.min.css";
@import "~datatables.net-bs4";

@import "./variables";
@import "~bootstrap";

// Estils locals
@import "components";
@import "main";
@import "menu_responsive";
@import "mediaqueries";
